import {
  ArrowTrendingDownIcon,
  ArrowTrendingUpIcon,
  HeartIcon,
  MinusIcon,
  PlusSmallIcon,
} from '@heroicons/react/24/outline';
import { Bars } from 'react-loader-spinner';
import { ChevronLeftIcon, ShoppingCartIcon, HeartIcon as SolidHeartIcon } from '@heroicons/react/24/solid';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Dropdown, { OptionType } from 'components/basic/dropdown';
import LoadingScreen from 'components/ui/loading-screen';
import CreateShoppingListModal from 'components/ui/create-shoppinglist-modal';
import SampleProductImage from 'assets/image/placeholder.jpg';
import styles from './style.module.scss';
import { getCategoryData, getCategoryProducts } from 'utils/api/category';
import { createFavorite, deleteFavorite } from 'utils/api/product';
import InfiniteScroll from 'react-infinite-scroll-component';

const ProductList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loadedPage, setLoadedPage] = useState<number>(1);
  const isTheLastPage = useRef(false);
  const { id } = useParams();
  const [isLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [products, setProducts] = useState<ProductType[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<ProductType | undefined>();
  const [showShoppingModal, setShowShoppingModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<OptionType>({
    name: t('shopping-all'),
    slug: t('shopping-all'),
  });
  const [subCategories, setSubCategories] = useState<CategoryType[]>([]);

  const setAsFavorite = async (product_id: number) => {
    await createFavorite(product_id);
  };

  const removeFavorite = async (product_id: number) => {
    await deleteFavorite(product_id);
  };

  const getSubCategories = async () => {
    if (id) {
      const res = await getCategoryData(parseInt(id));
      setSubCategories(res?.data?.categories ?? []);
    }
  };

  const getProducts = async () => {
    setIsSearching(true);
    if (id) {
      const res = await getCategoryProducts(selectedCategory?.id ? selectedCategory?.id : parseInt(id), 1);
      if (res?.data?.items.length < 20) {
        isTheLastPage.current = true;
      }
      setProducts(res?.data?.items);
      setLoadedPage(1);
    }
    setIsSearching(false);
  };

  const getNextProducts = async () => {
    if (id) {
      const res = await getCategoryProducts(selectedCategory?.id ? selectedCategory?.id : parseInt(id), loadedPage);
      console.log(res?.data?.items.length);
      console.log(products);
      if (res?.data?.items.length < 20) {
        isTheLastPage.current = true;
      }
      setProducts(prevProducts => [...prevProducts, ...res?.data?.items]);
      setLoadedPage(prevLoadedPage => prevLoadedPage + 1);
    }
  };

  useEffect(() => {
    setSelectedCategory({ name: 'Mind', slug: 'All' });
    getSubCategories();
  }, []);

  useEffect(() => {
    isTheLastPage.current = false;
    getProducts();
  }, [selectedCategory]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <Link to={'/'}>
          <ChevronLeftIcon width={24} height={24} />
        </Link>
        <p className={styles.title}>{`${t('search-select-sub-category')}`}</p>
      </div>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className={styles.productWrapper}>
          <Dropdown val={selectedCategory} onChange={setSelectedCategory} list={subCategories} />
          {isSearching ? (
            <div className={styles.loading}>
              <Bars color="#171100" width={30} height={30} />
            </div>
          ) : (
            <>
              {products?.length > 0 ? (
                <InfiniteScroll
                  dataLength={products.length}
                  next={() => {
                    if (!isTheLastPage.current) getNextProducts();
                  }}
                  hasMore={true}
                  loader={
                    !isTheLastPage.current && (
                      <div className={styles.loading}>
                        <Bars color="#171100" width={30} height={30} />
                      </div>
                    )
                  }
                  scrollableTarget="infinite_scroll"
                >
                  <div className={styles.productSearch}>
                    <div className={styles.productList}>
                      {products.map((product, index) => (
                        <div
                          key={index}
                          className={styles.item}
                          onClick={() => {
                            navigate(`/product/${product.id}`);
                          }}
                        >
                          <div className={styles.detail}>
                            <img
                              src={
                                product?.photo
                                  ? `${process.env.REACT_APP_BACKEND_BASEURL}/${product?.photo}`
                                  : SampleProductImage
                              }
                              className={styles.img}
                            />
                            <div className={styles.descritions}>
                              <div>
                                <p className={styles.name}>{product.name}</p>
                                <p className={styles.price}>{product.price} Ft</p>
                                <div className={styles.savingWrapper}>
                                  <p className={styles.saving}>
                                    {`${t('shopping-savings')}: ${product.saving ?? 0}`} Ft
                                  </p>
                                  <div className={styles.trending}>
                                    <div>
                                      {(product.trending ?? 0) > 2 && (
                                        <ArrowTrendingUpIcon width={18} height={18} className={styles.chartGood} />
                                      )}
                                      {(product.trending ?? 0) < -2 && (
                                        <ArrowTrendingDownIcon width={18} height={18} className={styles.chartDanger} />
                                      )}
                                      {(product.trending ?? 0) >= -2 && (product.trending ?? 0) <= 2 && (
                                        <MinusIcon width={18} height={18} className={styles.chargNormal} />
                                      )}
                                    </div>
                                    <p>{product.trending ?? 0}%</p>
                                  </div>
                                </div>
                              </div>
                              <p className={styles.description}>{product.description}</p>
                            </div>
                          </div>
                          <div className={styles.cart}>
                            <div
                              onClick={async e => {
                                e.stopPropagation();
                                if (product?.isFavorite) {
                                  await removeFavorite(product?.id);
                                } else {
                                  await setAsFavorite(product?.id);
                                }
                                await getProducts();
                              }}
                            >
                              {product?.isFavorite ? (
                                <SolidHeartIcon width={30} height={30} color="red" />
                              ) : (
                                <HeartIcon width={30} height={30} />
                              )}
                            </div>
                            <div
                              onClick={e => {
                                e.stopPropagation();
                                setSelectedProduct(product);
                                setShowShoppingModal(true);
                              }}
                              className={styles.shoppingCart}
                            >
                              <ShoppingCartIcon width={30} height={30} className={styles.shoppingCartIcon} />
                              <PlusSmallIcon width={12} height={12} className={styles.plusIcon} strokeWidth={5} />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </InfiniteScroll>
              ) : (
                <div className={styles.noProduct}>{t('search-no-product')}</div>
              )}
            </>
          )}
        </div>
      )}
      <CreateShoppingListModal
        showModal={showShoppingModal}
        setShowModal={setShowShoppingModal}
        product={selectedProduct}
      />
    </div>
  );
};

export default ProductList;

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TrashIcon,
  ChevronLeftIcon,
  PencilSquareIcon,
  ListBulletIcon,
  CheckIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { Bars } from 'react-loader-spinner';

import Button from 'components/basic/button';
import Drawer from 'components/basic/drawer';
import DropdownMenu from 'components/basic/dropdown-menu';
import ListItem from 'components/ui/list-item';
import Modal from 'components/basic/modal';
import ShoppingListItem from 'components/ui/shopping-list-item';
import TextInput from 'components/basic/text-input';
import styles from './style.module.scss';
import {
  createShoppingList,
  deleteShoppingList,
  updateShoppingList,
  saveSelectedList,
  getSavedSolutionList,
  deleteSavedSolution,
} from 'store/shopping/actions';
import { shoppingListSelector } from 'store/shopping/hooks';
import { useAppDispatch, useAppSelector } from 'store';

const ShoppingListManager = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const shopping = useAppSelector(shoppingListSelector);
  const [name, setName] = useState('');
  const [isRename, setIsRename] = useState(false);
  const [selectedList, setSelectedList] = useState<ShoppingListType | undefined>();
  const [sortBy, setSortBy] = useState<'ALPHA' | 'NEWEST'>('ALPHA');
  const [selectedPlan, setSelectedPlan] = useState<number>();
  const [showDeleteListModal, setShowDeleteListModal] = useState(false);
  const [showDeletePlanModal, setShowDeletePlanModal] = useState(false);
  const [showDeleteProductModal, setShowDeleteProductModal] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);

  const onUpdateShoppingList = () => {
    if (selectedList) {
      dispatch(
        updateShoppingList({
          ...selectedList,
          name,
        })
      );
      setShowRenameModal(false);
    }
  };

  const onDeleteShoppingList = () => {
    if (selectedList) {
      dispatch(deleteShoppingList(selectedList?.id));
      setShowDeleteListModal(false);
    }
  };

  const onDeleteShoppingPlan = () => {
    if (selectedPlan) {
      dispatch(deleteSavedSolution(selectedPlan));
    }
    setShowDeletePlanModal(false);
  };

  const showDetailDrawer = (item: ShoppingListType) => {
    setSelectedList(item);
    setShowDrawer(true);
  };

  useEffect(() => {
    if (shopping.loading === 'succeeded') {
      setSelectedList(prev => (shopping.list ?? []).find(item => item.id === prev?.id));
    }
  }, [shopping]);

  useEffect(() => {
    dispatch(getSavedSolutionList());
  }, []);

  return (
    <div className={styles.wrapper}>
      {shopping?.loading === 'succeeded' && shopping?.list && shopping?.savedSolutionList ? (
        <>
          <div>
            <p className={styles.title}>{t('shopping-your-shopping-list')}</p>
            {shopping?.list?.length > 0 ? (
              (shopping?.list ?? []).map(item => (
                <div key={item.id} className={styles.listItem}>
                  <div onClick={() => showDetailDrawer(item)}>
                    <ShoppingListItem {...item} />
                  </div>
                  <DropdownMenu
                    options={[
                      {
                        label: `${t('shopping-rename')}`,
                        icon: <PencilSquareIcon width={18} height={18} />,
                        onClick: () => {
                          setName(item?.name);
                          setSelectedList(item);
                          setShowRenameModal(true);
                        },
                      },
                      {
                        label: `${t('shopping-delete')}`,
                        icon: <TrashIcon width={18} height={18} />,
                        onClick: () => {
                          setSelectedList(item);
                          setShowDeleteListModal(true);
                        },
                      },
                    ]}
                  />
                </div>
              ))
            ) : (
              <>
                <p className={styles.noShoppingMessage}>{t('shopping-no-shopping-list-alert')}</p>
                <Button
                  label={`${t('product-create-new-shopping-list')}`}
                  onClick={() => {
                    dispatch(createShoppingList('Bevásárló lista #1'));
                  }}
                />
              </>
            )}
          </div>

          <div>
            <div className={styles.planWrapper}>
              {shopping?.savedSolutionList?.length > 0 && <p className={styles.title}>{t('shopping-your-plans')}</p>}
              {shopping?.savedSolutionList?.length > 0 ? (
                shopping?.savedSolutionList?.map((item, index) => {
                  const filteredList = shopping?.list.find(val => val.id === parseInt(item.list_id));
                  return (
                    <div className={styles.plan} key={index} onClick={() => navigate(`/shopping/solutions/${item.id}`)}>
                      <p className={styles.name}>
                        {filteredList?.name} ({filteredList?.product_count} {t('search-products')})
                      </p>
                      <p className={styles.created}>Created on: {item.created}</p>
                      <p className={styles.shopCount}>
                        {item.shop_count} {t('shop-count')} - {item.total}Ft {t('total-count')}
                      </p>
                      <button
                        className={styles.trash}
                        onClick={e => {
                          e.stopPropagation();
                          setShowDeletePlanModal(true);
                          setSelectedPlan(item.id);
                        }}
                      >
                        <TrashIcon width={24} height={24} />
                      </button>
                    </div>
                  );
                })
              ) : (
                <div className={styles.plan}>
                  <p className={styles.messageTitle}>{t('shopping-no-plan-title')}</p>
                  <p className={styles.message}>{t('shopping-no-plan-message')}</p>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className={styles.loading}>
          <Bars color="#171100" width={30} height={30} />
        </div>
      )}
      <Drawer isShow={showDrawer} setShow={setShowDrawer}>
        <div className={styles.drawer}>
          <div className={styles.header}>
            <button className={styles.goback} onClick={() => setShowDrawer(false)}>
              <ChevronLeftIcon width={24} height={24} />
            </button>
            {isRename ? (
              <>
                <input
                  type="text"
                  className={styles.input}
                  value={name}
                  onChange={e => {
                    setName(e.target.value);
                  }}
                />
                <div className={styles.buttonGroup}>
                  <button
                    type="button"
                    className={styles.button}
                    onClick={() => {
                      onUpdateShoppingList();
                      setIsRename(false);
                    }}
                  >
                    <CheckIcon width={20} height={20} />
                  </button>
                  <button type="button" className={styles.button} onClick={() => setIsRename(false)}>
                    <XMarkIcon width={20} height={20} />
                  </button>
                </div>
              </>
            ) : (
              <p className={styles.title}>{selectedList?.name}</p>
            )}
            <DropdownMenu
              options={[
                {
                  label: `${t('shopping-sort-latest')}`,
                  icon: <ListBulletIcon width={18} height={18} />,
                  onClick: () => {
                    setSortBy('NEWEST');
                  },
                },
                {
                  label: `${t('shopping-sort-alphabetical')}`,
                  icon: <ListBulletIcon width={18} height={18} />,
                  onClick: () => {
                    setSortBy('ALPHA');
                  },
                },
                {
                  label: `${t('shopping-rename')}`,
                  icon: <PencilSquareIcon width={18} height={18} />,
                  onClick: () => {
                    setName(selectedList?.name ?? '');
                    setSelectedList(selectedList);
                    setIsRename(true);
                  },
                },
                {
                  label: `${t('shopping-delete')}`,
                  icon: <TrashIcon width={18} height={18} />,
                  onClick: () => {
                    setSelectedList(selectedList);
                    setShowDeleteListModal(true);
                  },
                },
              ]}
            />
          </div>
          {selectedList?.products && selectedList?.products?.length > 0 ? (
            [...selectedList?.products]
              .sort((a, b) => {
                if (sortBy === 'ALPHA') return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                else return a.created && b.created ? (a.created.toLowerCase() < b.created.toLowerCase() ? -1 : 1) : 0;
              })
              .map(product => (
                <div key={product.id} onClick={() => navigate(`/product/${product.id}`)}>
                  <ListItem list_id={selectedList?.id} product={product} type="PRODUCT" controlAmount />
                </div>
              ))
          ) : (
            <p className={styles.noProductMessage}>{t('no-products')}</p>
          )}
          {selectedList?.products && selectedList?.products?.length > 0 ? (
            <Button
              label={`${t('shopping-plan-shopping')}`}
              onClick={() => {
                if (selectedList) {
                  dispatch(saveSelectedList(selectedList));
                }
                navigate('/shopping/plan');
              }}
            />
          ) : (
            <Button
              label={`${t('back-to-home')}`}
              onClick={() => {
                navigate('/');
              }}
            />
          )}
        </div>
      </Drawer>
      <Modal isOpen={showDeleteListModal} setIsOpen={setShowDeleteListModal}>
        <div className={styles.modal}>
          <p className={styles.alert}>{t('shopping-delete-list-alert', { value: 'Product name' })}</p>
          <div className={styles.buttonGroup}>
            <Button
              label={`${t('shopping-yes')}`}
              additionalClass={styles.button}
              onClick={() => onDeleteShoppingList()}
            />
            <Button
              label={`${t('shopping-no')}`}
              additionalClass={styles.button}
              onClick={() => setShowDeleteListModal(false)}
            />
          </div>
        </div>
      </Modal>
      <Modal isOpen={showDeletePlanModal} setIsOpen={setShowDeletePlanModal}>
        <div className={styles.modal}>
          <p className={styles.alert}>{t('shopping-delete-plan-alert')}</p>
          <div className={styles.buttonGroup}>
            <Button label={`${t('shopping-yes')}`} additionalClass={styles.button} onClick={onDeleteShoppingPlan} />
            <Button
              label={`${t('shopping-no')}`}
              additionalClass={styles.button}
              onClick={() => setShowDeletePlanModal(false)}
            />
          </div>
        </div>
      </Modal>
      <Modal isOpen={showDeleteProductModal} setIsOpen={setShowDeleteProductModal}>
        <div className={styles.modal}>
          <p className={styles.alert}>{t('shopping-delete-product-alert', { value: 'Product name' })}</p>
          <div className={styles.buttonGroup}>
            <Button label={`${t('shopping-yes')}`} additionalClass={styles.button} onClick={() => {}} />
            <Button
              label={`${t('shopping-no')}`}
              additionalClass={styles.button}
              onClick={() => setShowDeleteProductModal(false)}
            />
          </div>
        </div>
      </Modal>
      <Modal isOpen={showRenameModal} setIsOpen={setShowRenameModal}>
        <div className={styles.modal}>
          <div className={styles.input}>
            <TextInput
              name="list-name"
              val={name}
              onChange={e => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className={styles.buttonGroup}>
            <Button label={`${t('shopping-yes')}`} additionalClass={styles.button} onClick={onUpdateShoppingList} />
            <Button
              label={`${t('shopping-no')}`}
              additionalClass={styles.button}
              onClick={() => setShowRenameModal(false)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ShoppingListManager;
